@import '../../../styles/breakpoints';
@import '../../../styles/effects';
@import '../../../styles/spacing';

.box {
  width: auto;
  margin-bottom: $bottomPageMargin;

  text-align: left;

  background: #fff;

  @media (min-width: $tablet) {
    padding: 4rem 3rem;

    border-radius: 2rem;
    box-shadow: $baseBoxShadow;
  }

  @media (min-width: $laptopM) {
    padding: 5rem 8rem;
  }

  @media (min-width: $laptopXL) {
    width: $laptopM;
  }
}
