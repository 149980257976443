@import '../../styles/breakpoints';
@import '../../styles/effects';
@import '../../styles/spacing';
@import '../../styles/typography';

.invisible { // TODO remove when unused
  display: none !important;
}

div.field {
  margin-bottom: $defaultSpacing;
}

.row {
  flex-wrap: wrap;

  width: 100%;

  display: flex;
  flex-direction: row;
}

.header {
  @extend %sectionHeader;

  margin-top: calc(#{$defaultSpacing} * 3);
}

.column {
  width: 100%;
}

// Takes ~ 2/3 of the full width > $tablet resolution
.wideColumn {
  width: 100%;
}

.fullwidthColumn {
  width: 100%;
}

.mobileOnly {
  display: none !important;
}

@media (min-width: $tablet) {
  .column {
    flex: 1;

    margin-right: 3.4rem;
  }

  .wideColumn {
    flex: 2.1;

    margin-right: 3.4rem;
  }

  .fullwidthColumn {
    display: block;
  }
}

@media (min-width: $laptopM) {
  .column {
    margin-right: 8rem;
  }

  .wideColumn {
    flex: 2.32;

    margin-right: 8rem;
  }

  .fullwidthColumn {
    padding-right: 8rem;
  }
}

@media (min-width: $laptopL) {
  .wideColumn {
    flex: 2.25;
  }
}
