@import '../../../../../styles/breakpoints';
@import '../../../../../styles/typography';
@import '../../../../../styles/spacing';

.inputsContainer {
  width: 100%;
  margin-bottom: calc(#{$defaultSpacing * 2});

  @media (min-width: $tablet) {
    width: 50%;
  }
}

.fullWidth {
  width: 100% !important;
}

.passwordRestrictions {
  font-size: 1.2rem;
  line-height: 1.92;

  color: $textColorSecondary;
}
