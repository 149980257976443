@import '../../styles/effects';
@import '../../styles/typography';
@import '../../styles/colors';
@import '../../styles/breakpoints';
@import '../../styles/sizes';
@import '../../styles/spacing';

$bottomNavMobileHeight: 6rem;

$blobBorderRadius: 20.75rem;
$blobWidth: 180rem;
$blobHeight: 100rem;

$blueBlobBaseTop: -12%;
$blueBlobBaseLeft: 30%;

$contentPaddingMobile: 2.4rem;
$contentPaddingTablet: 4rem;
$contentPaddingLaptop: 12rem;

.container {
  position: relative;

  width: 100%;
  height: 100%;

  .backgroundContainer {
    position: absolute;
    top: 0;
    left: 50%;

    width: 50%;
    height: 100%;

    .backgroundColor {
      position: fixed;
      z-index: 2;

      display: none;

      width: $blobWidth;
      height: $blobHeight;

      transform: rotate(-315deg);

      border-radius: $blobBorderRadius;
      background-color: $primaryColorLight;
      box-shadow: $baseBoxShadow;

      @media (min-width: $tablet) {
        top: -12%;
        left: 30%;

        display: block;
      }

      @media (min-width: $laptopXS) {
        left: 35%;
      }

      @media (min-width: $laptopM) {
        top: -13%;
        left: 40%;
      }

      @media (min-width: $laptopL) {
        top: -15%;
        left: 47%;

        width: 2100px;
        height: 1200px;
      }

      @media (min-width: $laptopXL) {
        top: -35%;
        left: 52%;

        width: 3000px;
        height: 2000px;
      }
    }

    .backgroundGreyLine {
      position: fixed;
      z-index: 1;

      display: none;

      width: $blobWidth;
      height: $blobHeight;

      transform: rotate(-302deg);

      opacity: .26;
      border: solid 1px #979797;
      border-radius: $blobBorderRadius;
      box-shadow: $baseBoxShadow;

      @media (min-width: $tablet) {
        top: -7%;
        left: 18%;

        display: block;
      }

      @media (min-width: $laptopM) {
        top: -15%;
        left: 30%;
      }

      @media (min-width: 1590px) {
        left: 40%;
      }

      @media (min-width: $laptopL) {
        top: -20%;
        left: 37%;

        width: 2100px;
        height: 1200px;
      }

      @media (min-width: $laptopXL) {
        top: -25%;
        left: 46%;

        width: 2800px;
        height: 1900px;
      }
    }
  }

  .content {
    font-family: $font;

    position: relative;
    z-index: 3;

    overflow-x: hidden;

    height: inherit;
    padding: 0 $contentPaddingMobile;

    text-align: center;

    @media (min-width: $tablet) {
      padding: 0 $contentPaddingTablet $bottomPageMargin;
    }

    @media (min-width: $laptopXS) {
      overflow-x: unset;
    }

    @media (min-width: $laptopM) {
      padding: 0 $contentPaddingLaptop $bottomPageMargin;
    }

    @media (min-width: $laptopL) {
      width: $laptopM;
      margin: 0 auto;
      padding: 0 !important;
    }

    .nav {
      overflow: hidden;
      justify-content: space-between;

      padding: 3rem 0;

      display: flex;
      flex-direction: row;

      img {
        width: $mediumIconSize;
        height: $mediumIconSize;
      }

      a {
        font-size: $fontLarge;

        align-items: center;

        text-decoration: none;

        color: $textColorDark;

        display: flex;
        flex-direction: row;

        &:visited {
          outline: none;
        }

        .homeLink {
          font-size: $fontLarge;
          line-height: normal;

          margin-left: 2.4rem;

          color: $textColorDark;
        }
      }

      @media (min-width: $tablet) {
        a .homeLink {
          font-size: 2.4rem;

          margin-left: 3.2rem;
        }

        img {
          width: $largeIconSize;
          height: $largeIconSize;
        }
      }

      .rightSide {
        align-items: center;

        display: flex;
        flex-direction: row;

        a {
          font-size: $fontSmall;
          display: none;
        }

        @media (min-width: $tablet) {
          a {
            display: flex;
            margin-right: 2rem;
            color: $textColorLight;

            &:hover,
            &:focus {
              text-decoration: underline;

              text-decoration-color: $textColorLight;
            }
          }
        }

        @media (min-width: $laptopM) {
          a {
            margin-right: 3.3rem;
          }
        }
      }
    }
  }

  .bottomNavMobile {
    position: fixed;
    z-index: 4;
    bottom: 0;
    left: 0;

    width: 100%;
    height: $bottomNavMobileHeight;

    text-align: center;

    display: flex;
    flex-direction: row;

    a {
      font-size: $fontSmall;

      align-items: center;
      justify-content: center;
      flex: 1;

      text-decoration: none;

      color: $white !important;
      background-color: $primaryHighlight;

      display: flex;

      &:hover,
      &:focus {
        background-color: $primaryHighlightLighter;
      }
    }

    @media (min-width: $tablet) {
      display: none;
    }
  }
}
