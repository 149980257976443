@import url('https://fonts.bunny.net/css2?family=Montserrat:wght@500;600;700;800;900&display=swap');
@import './styles/breakpoints';
@import 'styles/typography';
@import './styles/sizes';
@import './styles/spacing';
@import './components/Layout/Layout.module';

html {
  font-size: $fontBase !important;

  height: 100%;

  color: $textColorDark !important;

  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  overflow: auto;

  height: inherit;
  margin: 0;
}

#root {
  height: inherit;
}

h3, h4, h5, h6 {
  font-weight: $fontWeightBold;
}

p {
  margin-bottom: $defaultSpacing;
}

.grecaptcha-badge {
  z-index: 999;
  bottom: calc(#{$bottomNavMobileHeight} + #{$defaultSpacing}) !important;

  display: block !important;
}

@media (min-width: $tablet) {
  .grecaptcha-badge {
    bottom: 1.4rem !important;
  }
}

// HIDE ARROWS IN NUMERIC INPUT
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// ANT DESIGN OVERRIDES
.ant-select-selector {
  line-height: 4.2rem !important;

  align-items: center;

  height: 4.6rem !important;

  border-radius: 0 !important;
}

::selection {
  background-color: $primaryHighlight; /* Background color for the selected text */
  color: $white; /* Color of the text that is selected */
}

a {
  &:hover {
    color: $primaryHighlight !important;
  }
}
