@import '../../../../../styles/spacing';
@import '../../../../../styles/typography';

.logo {
  margin-top: calc(-1 * #{$defaultSpacing});
  margin-bottom: $defaultSpacing;
}

h1.header {
  font-weight: $fontWeightBold;
}
