@import '../../../../styles/breakpoints';
@import '../../../../styles/typography';
@import '../../../../styles/spacing';

$borderColor: #f4f4f5;

.content {
  overflow: auto;

  display: flex;
  flex-direction: column;

  @media (min-width: $tablet) {
    flex-direction: row;
  }

  .left {
    padding-bottom: 3.4rem;

    color: $textColorDark;

    display: flex;
    flex-direction: column;

    h1 {
      font-size: 3.2rem;
      line-height: normal;
    }

    .info {
      font-size: 1.4rem;

      margin-bottom: 4rem;
    }

    @media (min-width: $tablet) {
      width: 50%;
      padding-right: 3.4rem;
      padding-bottom: 0;
    }

    @media (min-width: $laptopXS) {
      padding-right: 7rem;
    }

    @media (min-width: $laptopL) {
      padding-right: 15rem;
    }
  }

  .right {
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 1.7rem;

    text-align: left;

    border-top: 1px solid $borderColor;

    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.6rem;
      line-height: 1.94;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.92;

      color: $textColorSecondary;
    }

    @media (min-width: $tablet) {
      width: 50%;
      padding: 0 3.4rem;

      border-top: none;
      border-left: 1px solid $borderColor;

      .withTopMargin {
        margin-top: calc(2rem + 24px);
      }
    }

    @media (min-width: $laptopXS) {
      padding: 0 7rem;
    }

    @media (min-width: $laptopL) {
      padding: 0 15rem;
    }
  }
}
