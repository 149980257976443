@import '../../../styles/breakpoints';
@import '../../../styles/spacing';

.dropdown {
  padding: $defaultSpacing !important;
}

.dropdownButton {
  width: auto !important;
  max-width: 11rem;

  .dropdownInnerContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    height: 100%;

    .dropdownButtonText {
      overflow: hidden;

      margin: 0;
      margin-right: $defaultSpacing;

      text-align: left;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: $tablet) {
    max-width: 20rem;
  }

  @media (min-width: $laptopS) {
    max-width: 30rem;
  }
}

.menuItem {
  display: flex;
  align-items: center;
}
