@import '../../styles/breakpoints';
@import '../../styles/spacing';
@import '../../styles/typography';

.sectionContainer {
  margin-bottom: calc(#{$defaultSpacing * 2});

  border-bottom: solid 1px #d0d0d0;

  .header {
    @extend %sectionHeader;
  }

  .subheader {
    font-size: 1.4rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;

    letter-spacing: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.flexbox {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.buttonLink {
  min-width: auto;
  background: transparent !important;
}

.bottomInfo {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;

  margin-top: 4rem;

  letter-spacing: normal;

  @media (min-width: $laptopXS) {
    width: 50%;
  }
}
