@import '../../../../../styles/typography';
@import '../../../../../styles/spacing';

.passwordContainer {
  margin-bottom: $defaultSpacing;
}

.registerButton {
  margin-top: $defaultSpacing;
}

.passwordRestrictions {
  font-size: 1.2rem;
  line-height: 1.92;

  color: $textColorSecondary;
}

.agreeTermsContainer {
  margin-bottom: calc(#{$defaultSpacing * 2}) !important;
}

.agreeTerms {
  font-size: 1.2rem !important;
  line-height: 1.75 !important;

  margin-bottom: $defaultSpacing !important;
}
