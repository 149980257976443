@import '../../../styles/colors';
@import '../../../styles/spacing';
@import '../../../styles/typography';
@import '../../../styles/effects';

$lineHeight: 2.2;

.container {
  .label {
    font-size: $fontMedium;
    line-height: $lineHeight;

    margin-bottom: $defaultSpacing;

    display: flex;
    flex-direction: row;
  }

  .error {
    font-size: $fontMedium;
    line-height: $lineHeight;

    padding: 0 $defaultSpacing;

    color: $textColorLight;
    border: solid 1px $errorColor;
    border-radius: 4px;
    background: $errorBgColor;
    display: flex;
    flex-direction: column;
  }
}
