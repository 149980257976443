@import 'spacing';

$font: Montserrat, sans-serif;

$fontBase: 10px;
$fontSmall: 1.2rem;
$fontMedium: 1.4rem;
$fontDefault: 1.6rem;
$fontLarge: 1.8rem;

$fontWeightBold: 600;

$textColorLight: #fffff;
$textColorDark: #3d3d3d;
$textColorSecondary: #596165;

:export {
  fontDefault: $fontDefault;
  textColorDark: $textColorDark;
}

%sectionHeader {
  font-weight: $fontWeightBold;
  line-height: 1.94;
}
