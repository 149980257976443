@import '../../../../../styles/spacing';

.finishRegistration {
  font-size: 1.4rem;

  margin-bottom: $defaultSpacing;
}

.spaceBottomContainer {
  margin-bottom: calc(#{$defaultSpacing * 2});
}

