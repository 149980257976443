@import "../../styles/colors";
@import "../../styles/typography";

.price {
  font-weight: $fontWeightBold;
  color: $primaryColor;
}


.discount {
  font-weight: $fontWeightBold;
  color: $primaryHighlight;
}
