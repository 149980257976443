@import '../../styles/effects';
@import '../../styles/spacing';

.container {
  display: grid;
  place-items: center;
}

.info {
  margin-bottom: calc(#{$defaultSpacing * 2});
}
