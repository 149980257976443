@import '../../../../../styles/breakpoints';
@import '../../../../../styles/spacing';

h1 {
  font-size: 3.2rem;
  line-height: normal;
}

form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.otpLabel {
  font-size: 1.4rem;

  margin-bottom: 1rem;
}

.resendContainer {
  margin-top: 2rem;
}

.resendInfo {
  margin-right: 1rem;
}

.confirmationField {
  margin-bottom: $defaultSpacing;
}

.resendButton {
  height: auto !important;
  padding: 0 !important;
}
