@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/spacing';
@import '../../styles/typography';

.marginTop {
  margin-top: $defaultSpacing;
}

.marginBottom {
  margin-bottom: $defaultSpacing;
}

.subparagraph {
  font-size: $fontMedium;
}

.secondaryLine {
  font-size: 1.1rem;
  margin-top: 1.5rem !important; /* Adjust this value as needed */
}

.header {
  @extend %sectionHeader;
}

.row {
  flex-wrap: wrap;

  width: 100%;

  display: flex;
  flex-direction: row;
}

.rowSpaceBetween {
  @extend .row;

  justify-content: space-between;
}
.totalPrice {
  font-size: 3.2rem;
  font-weight: 600;
}

.column {
  width: 100%;
}

.hline {
  border: solid 1px #d0d0d0;
  width: 100%;
  margin: 2rem 0;
}

.vline {
  border: solid 1px #d0d0d0;
  margin: 0 3rem;
}

.mobileTopSpace {
  margin-top: calc(#{$defaultSpacing * 2});
}

@media (min-width: $tablet) {
  .column {
    flex: 1;
  }
}

@media (min-width: $laptopM) {
  .vline {
    margin: 0 9rem;
  }
}
