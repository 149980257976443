@import '../../styles/typography';

.container {
  h2 {
    margin-bottom: calc(3 * #{$defaultSpacing});
  }

  p {
    margin-bottom: 0;
  }

  .section {
    margin-bottom: $defaultSpacing;
  }

  .header {
    @extend %sectionHeader;
  }
}
