@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/effects';
@import '../../styles/spacing';
@import '../../styles/typography';

.row {
  flex-wrap: wrap;

  width: 100%;

  display: flex;
  flex-direction: row;
}

.column {
  width: 100%;
}

.header {
  @extend %sectionHeader;

  margin-top: calc(#{$defaultSpacing} * 3);
}

.mobileOnly {
  display: none !important;
}

.fileLinkButton {
  font-weight: 600;
  line-height: 2.33;

  cursor: pointer;
  letter-spacing: -.43px;

  color: $primaryColor;

  &:hover {
    color: $primaryColorLight;
  }
}

.resultsTable {
  overflow: hidden;

  margin-bottom: 5rem;

  .phoneNumberCell {
    white-space: nowrap;
  }
}

@media (min-width: $tablet) {
  .column {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

@media (min-width: $laptopXS) {
  .mobileOnly {
    display: block !important;
  }

  .resultsTable {
    margin-right: 50%;
  }
}
