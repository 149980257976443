@import '../../styles/breakpoints';
@import '../../styles/effects';
@import '../../styles/typography';

.home {
  justify-content: space-between;

  padding: 1rem 0 8rem 0;

  display: flex;
  flex-direction: column;

  @media (min-width: $laptopXS) {
    align-items: center;
    padding: 4rem 0 8rem 0;
    flex-direction: row;
  }

  .left {
    padding-right: 4rem;

    color: $textColorDark;

    .bookButtonContainer {
      display: flex;
      flex-direction: row;
    }

    h1 {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1.29;

      text-align: left;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.92;

      margin: 2.4rem 0;

      text-align: left;
    }

    .secondaryLine {
      font-size: 1.1rem;
    }

    @media (min-width: $tablet) {
      h1 {
        font-size: 4.4rem;
        line-height: 1.29;
      }

      p {
        font-size: 1.6rem;
        line-height: 1.94;
      }
    }

    @media (min-width: $laptopXS) {
      width: 50%;
      h1 {
        font-size: 4.8rem;
        line-height: 1.29;
      }
    }

    @media (min-width: $laptopM) {
      width: 60%;

      h1 {
        font-size: 6rem;
        line-height: 1.11;
      }
    }
  }

  .right {
    margin-top: 4rem;

    @media (min-width: $tablet) {
      width: 100%;
    }

    @media (min-width: $laptopXS) {
      margin-top: 0
    }
  }
}
