@import '../../../styles/effects';
@import '../../../styles/typography';
@import '../../../styles/colors';
@import '../../../styles/breakpoints';
@import '../../../styles/sizes';
@import '../../../styles/spacing';

.mobileMenuModalContent {
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  padding-bottom: 1rem;

  border-radius: 0;
}

.mobileMenuHeader {
  font-size: $fontLarge;
  line-height: 1.72;

  display: flex;
  align-items: center;

  padding: 2.4rem 1.7rem;

  .appName {
    font-size: $fontLarge;
    line-height: normal;

    margin-left: 2.4rem;

    color: $textColorDark;
  }
}

.mobileMenuContent {
  height: 100%;
}

.navMenuContainer {
  padding: 1rem !important;

  border-radius: 1rem !important;

  display: flex;
  flex-direction: column;
  height: 100%;

  .navMenuItem {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;

    letter-spacing: -.57px;
  }
}

.navMenuTitle {
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: -.57px;
}

.navMenuSubtitle {
  font-size: $fontMedium;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

  display: block;

  letter-spacing: normal;
}
