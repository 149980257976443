@import '../../../styles/breakpoints';
@import '../../../styles/effects';
@import '../../../styles/colors';
@import '../../../styles/spacing';

.banner {
  flex-direction: column;
  display: flex;

  .logo {
    margin-bottom: calc(2 * #{$defaultSpacing});
  }

  @media (min-width: $laptopXS) {
    text-align: left;
    flex-direction: row;
    padding: 5rem;
    border-radius: 2rem;
    background-color: $white;
    box-shadow: 0 10px 70px 0 rgba(25, 27, 61, .1);
  }

  .banner_left {
    flex-direction: column;
    display: flex;
    flex: 1;
    padding-right: 30px;
    border-radius: 2rem;
    text-align: left;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: $tablet) {
      background-color: $white;
      padding: 2rem;
      border-radius: 2rem;
      text-align: left;
      box-shadow: 0 10px 70px 0 rgba(25, 27, 61, .1);
      width: 100%;
    }

    @media (min-width: $laptopXS) {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0 30px 0 0;
    }
  }

  .banner_right {
    display: none;
    @media (min-width: $laptopXS) {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 10px solid #fff; /* Frame */
    box-shadow: 4px 4px 10px 4px rgba(25, 27, 61, .1); /* Shadow for the image */
  }


  /* Style for the image */
  .framed_image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  h3 {
    font-weight: bold;
    padding-bottom: 1rem;
    text-align: left;
  }

  .pricingLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.92;
    padding-bottom: $defaultSpacing;
    padding-right: 1em;
    width: 100%;

    @media (min-width: $laptopXS) {
      line-height: 1.94;
    }
  }
}
