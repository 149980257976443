@import '../../../styles/breakpoints';
@import '../../../styles/effects';

.overlay {
  position: relative;
  z-index: -1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  color: rgba(130, 130, 130, .5);

  display: grid;
  place-items: center;
}

.fullscreen {
  position: fixed;
}

.background {
  min-width: 38rem;
  min-height: 15rem;
  text-align: center;

  @media (min-width: $tablet) {
    background: white;
    padding: 4rem;
    box-shadow: $baseBoxShadow;
    border-radius: 2rem;
  }
}
