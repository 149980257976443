@import '../../../styles/breakpoints';
@import '../../../styles/spacing';
@import '../../../styles/sizes';
@import '../../../components/Layout/Layout.module';

.ant-message-notice-content {
  box-shadow: none !important;
}

.ant-message {
  bottom: calc(#{$bottomNavMobileHeight} + #{$defaultSpacing}) !important;
  top: auto !important;

  @media (min-width: $tablet) {
    bottom: initial !important;
    top: .8rem !important;
  }
}

