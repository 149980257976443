@import '../../../styles/breakpoints';
@import '../../../styles/spacing';

.radioGroup {
  margin-bottom: $defaultSpacing !important;
}

.radio {
  display: block !important;

  padding: $defaultSpacing 0 !important;

  @media (min-width: $tablet) {
    display: unset !important;
  }
}

.vertical {
  display: block !important;
}
