/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    /*border: 1px solid rgba(0, 0, 0, 0.1);*/
    /*border-radius: 2px;*/
    border: none;
}

.accordion__item + .accordion__item {
    /*border-top: 1px solid rgba(0, 0, 0, 0.1);*/
    border: none;
}

.accordion__button {
    /*background-color: #f4f4f4;*/
    /*color: #444;*/
    cursor: pointer;
    font-weight: 600;
    /*padding: 18px;*/
    /*padding-right: 30px;*/
    padding: 1rem 0;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__button[aria-expanded='true'],
.accordion__button[aria-selected='true'] {
    /*text-decoration: underline;*/
    /*font-weight: bold;*/
}

.accordion__button:hover {
    /*background-color: #ddd;*/
    text-decoration: underline;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(-45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    /*padding: none;*/
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        /*height: 0;*/
        opacity: 0;
    }

    100% {
        /*height: 100;*/
        opacity: 1;
    }
}
