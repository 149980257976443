@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/typography';

button.button {
  font-size: $fontSmall;
  line-height: 1.75;

  min-width: 14rem;
  height: 48px;

  letter-spacing: -.43px;

  // This always changes the hover colour even when inactive
  //&:hover {
  //  background: $primaryHighlight !important;
  //  border: $primaryHighlight !important;
  //}

  @media (min-width: $tablet) {
    font-size: unset;

    width: 22rem;
    height: 6rem;

    letter-spacing: -.57px;
  }
}

.buttonSecondary {
  background: $primaryHighlight !important;
  border: $primaryLighter !important;

  &:hover {
    background: $primaryHighlightLighter !important;
    border: $primaryHighlightLighter !important;
  }

  @extend .button;
}

.link {
  width: auto !important;
  height: auto !important;

  &:focus {
    color: $linkColor !important;
  }

  &:hover {
    color: $primaryHighlight !important;
  }
}
