$primaryColor: #7d845e;
$primaryLighter: #b8b799;
$primaryColorLight: #d8cfc4;
$primaryHighlight: #b7410e;
$primaryHighlightLighter: #c05324;
$linkColor: #d2b48c;
$errorColor: #f25e5e;
$errorBgColor: rgba(242, 94, 94, .75);
$successColor: #48c980;
$infoColor: #d8cfc4;
$white: #fff;
$oldstripebrand: #f19b22;
$oldstripeaccent: #9e162e;

:export {
  primaryColor: $primaryColor;
  errorColor: $errorColor;
  errorBgColor: $errorBgColor;
  successColor: $successColor;
}

tiran {
  --fontFamily: Arial;
  --fontWeight: 400;
  --textTransform: none;
  --textDecoration: none;
  --fontSize: 16px;
  --lineHeight: 1.6;
  --letterSpacing: 0em;
  --buttonFontFamily: Oswald, Sans-Serif;
  --buttonFontWeight: 400;
  --buttonTextTransform: uppercase;
  --buttonFontSize: 16px;
  --buttonLetterSpacing: 2px;
  --has-classic-forms: var(--true);
  --has-modern-forms: var(--false);
  --form-field-border-initial-color: var(--border-color);
  --form-field-border-focus-color: var(--paletteColor1);
  --form-selection-control-initial-color: var(--border-color);
  --form-selection-control-accent-color: var(--paletteColor1);
  --paletteColor1: #B7410E;
  --paletteColor2: #D2B48C;
  --paletteColor3: #D8CFC4;
  --paletteColor4: #7D845E;
  --paletteColor5: #B8B799;
  --paletteColor6: #313131;
  --paletteColor7: #FAFBFC;
  --paletteColor8: #ffffff;
  --color: var(--paletteColor6);
  --linkInitialColor: var(--paletteColor2);
  --linkHoverColor: var(--paletteColor3);
  --selectionTextColor: var(--paletteColor8);
  --selectionBackgroundColor: var(--paletteColor1);
  --border-color: var(--paletteColor5);
  --headings-color: var(--paletteColor4);
  --heading-1-color: var(--paletteColor1);
  --content-spacing: 1.5em;
  --buttonMinHeight: 40px;
  --buttonShadow: none;
  --buttonTransform: none;
  --buttonTextInitialColor: #ffffff;
  --buttonTextHoverColor: #ffffff;
  --buttonInitialColor: var(--paletteColor2);
  --buttonHoverColor: var(--paletteColor3);
  --button-border: none;
  --buttonBorderRadius: 0px;
  --button-padding: 5px 20px;
  --normal-container-max-width: 1290px;
  --content-vertical-spacing: 60px;
  --narrow-container-max-width: 750px;
  --wide-offset: 130px;
}
