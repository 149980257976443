@import '../../../../../styles/spacing';

.fullWidthFormContainer {
  width: 100%;
  margin-bottom: $defaultSpacing;
}

.loginButtonsContainer {
  margin-bottom: calc(#{$defaultSpacing * 2});
}
