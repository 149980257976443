@import '../../styles/typography';
@import '../../styles/spacing';

.container {
  h2 {
    margin-bottom: calc(3 * #{$defaultSpacing});
  }

  h3 {
    margin-top: calc(3 * #{$defaultSpacing});
  }

  ol {
    counter-reset: list;
    padding-left: 0;

    & > li {
      list-style: none;
      margin-bottom: $defaultSpacing;

      &:before {
        margin-right: calc(2 * #{$defaultSpacing});
        content: "(" counter(list) ") ";
        counter-increment: list;
      }
    }
  }

  .header {
    @extend %sectionHeader;
    display: block;
  }
}
