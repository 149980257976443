@import '../../../styles/breakpoints';
@import '../../../styles/effects';
@import '../../../styles/typography';

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: calc(100vw - 4.8rem);
  min-height: 240px;

  border-radius: 2rem;

  @media (min-width: $tablet) {
    width: 350px;
  }
}

.header {
  @extend %sectionHeader;

  padding: 2.4rem 2.4rem 0 2.4rem;
}

.content {
  padding: 0 2.4rem;
}

.actionButtons {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  height: 6rem;

  .button {
    flex: 1;
    white-space: pre-wrap;
  }

  .divider {
    width: 1px;
    height: 100%;

    opacity: .25;
    background: #97abba;
  }
}

.actionButtons::before {
  position: absolute;
  top: 0;

  width: 100%;
  height: 1px;

  content: '';

  opacity: .25;
  background: #97abba;
}
